export const QUOTES = {
  'balev-59': {
    company: 'Synergon',
    person: 'Filip Balev',
    quote: `We've been able to cut delivery costs by 20% in six months.`,
    title: 'Director of Operations',
  },
  'balev-89': {
    company: 'Synergon',
    person: 'Filip Balev',
    quote: `We've been able to cut costs by 20%. Field agents make fewer miles while delivering more.`,
    title: 'Director of Operations',
  },
  'bartov-100': {
    company: 'Synergon',
    person: 'Dimitar Bartov',
    quote: `The Analytics dashboard helps us discover inefficiencies and address them when they are still small.`,
    title: 'CEO',
  },
  'bartov-88': {
    company: 'Synergon',
    person: 'Dimitar Bartov',
    quote: `The integration with Microsoft Dynamics is so smooth that it feels like a single system.`,
    title: 'CEO',
  },
  'becker-173': {
    company: 'Tmarket',
    person: 'Bertha Becker',
    quote: `The customer portal helps us boost retention. We can promote loyalty programs and coupons and it also helps us understand how our customers feel about our delivery service.`,
    title: 'Customer Retention Manager',
  },
  'becker-208': {
    company: 'Tmarket',
    person: 'Bertha Becker',
    quote: `Collecting feedback upon delivery is so easy. It seems our customers love the feedback form as we get tons of comments on what they like or what we can improve. This has been key to maintaining great service.`,
    title: 'Customer Retention Manager',
  },
  'gadzhev-124': {
    company: 'Delita Trade',
    person: 'Radostin Gadzhev',
    quote: `Our drivers love the driver app. It allows them to do their job faster and easily adapt to last minute changes in schedules.`,
    title: 'Commercial & Operations Director',
  },
  'gadzhev-52': {
    company: 'Delita Trade',
    person: 'Radostin Gadzhev',
    quote: `The driver app helped us decrease driver time by 20%`,
    title: 'Commercial & Operations Director',
  },
  'gadzhev-62': {
    company: 'Delita Trade',
    person: 'Radostin Gadzhev',
    quote: `Ufleet turns hours of manual route planning into 1-click task.`,
    title: 'Commercial & Operations Director',
  },
  'gadzhev-86': {
    company: 'Delita Trade',
    person: 'Radostin Gadzhev',
    quote: `With the ufleet driver app we considerably improved our new driver onboarding process.`,
    title: 'Commercial & Operations Director',
  },
  'kolev-175': {
    company: 'Delita Trade',
    person: 'Atanas Kolev',
    quote: `Having total operational visibility in one place is a game changer. I cannot imagine going back to the times when everything was running on different systems and spreadsheets!`,
    title: 'Sales Director',
  },
  'kowalska-91': {
    company: 'OBI',
    person: 'Katarzyna Kowalska',
    quote: `The transparency we give our customers at every step of the delivery process is phenomenal!`,
    title: 'Supply Chain Director',
  },
  'król-201': {
    company: 'OBI',
    person: 'Marek Król',
    quote: `Thanks to Ufleet, we no longer get questions from customers when our truck will be at the address. The transparent delivery process greatly improves the service quality of our logistics subcontractors.`,
    title: 'Store Director',
  },
  'kwoska-319': {
    company: 'PwC',
    person: 'Magda Kwoska',
    quote: `As Innovation Manager at PwC I engage with innovative tech companies daily. Ufleet is that new breed of software entrepreneurs who are redefining the concept of enterprise software. It was a unique experience to see the implementation of such a complex system so fast and without the typical massive upfront sunk costs.`,
    title: 'Innovations Manager',
  },
  'marinov-100': {
    company: 'Devin',
    person: 'Vasil Marinov',
    quote: `Before Ufleet, dispatchers spent half of their workday in route planning. Now it takes them an hour.`,
    title: 'Supply Chain and Procurement Director',
  },
  'marinov-120': {
    company: 'Devin',
    person: 'Vasil Marinov',
    quote: `Ufleet improved our route optimization process, helping us prevent inefficient use of resources and unreasonable routes.`,
    title: 'Supply Chain and Procurement Director',
  },
  'marinov-132': {
    company: 'Devin',
    person: 'Vasil Marinov',
    quote: `With Ufleet, onboarding new drivers is quick and easy and we achieved an even and fair workload distribution among delivery drivers.`,
    title: 'Supply Chain and Procurement Director',
  },
  'marinov-157': {
    company: 'Devin',
    person: 'Vasil Marinov',
    quote: `The platform significantly reduces the workload of dispatchers. Before, dispatchers spent half of their workday in route planning. Now it takes them an hour.`,
    title: 'Supply Chain and Procurement Director',
  },
  'marinov-163': {
    company: 'Devin',
    person: 'Vasil Marinov',
    quote: `The implementation and onboarding process was easy and smooth. We had custom integration requirements that the Ufleet team addressed and helped to set up properly.`,
    title: 'Supply Chain and Procurement Director',
  },
  'mroz-143': {
    company: 'OBI',
    person: 'Adam Mroz',
    quote: `Investing in modern technologies in the area of last mile delivery is a must nowadays. With Ufleet, such an investment was an outright success.`,
    title: 'Logistics Manager',
  },
  'mroz-367': {
    company: 'OBI',
    person: 'Adam Mroz',
    quote: `Investing in modern technologies in the area of last mile delivery is a must nowadays. With Ufleet, such an investment was an outright success. It is the perfect example of cooperation between developers, engineers and business practitioners, focused on the customer. I am so proud with the results, which at the end of the day come out as pure customer satisfaction.`,
    title: 'Logistics Manager',
  },
  'mroz-72': {
    company: 'OBI',
    person: 'Adam Mroz',
    quote: `Half of our clients interact with the ufleet powered OBI customer portal`,
    title: 'Logistics Manager at OBI',
  },
  'pawłowska-83': {
    company: 'OBI',
    person: 'Laura Pawłowska',
    quote: `Extremely convenient status updates. Always on time. I trust OBI and their drivers.`,
    title: 'Customer',
  },
  'petkov-240': {
    company: 'Tmarket',
    person: 'Pavel Petkov',
    quote: `Customers are in love with our delivery process. They now receive updates on delivery times and can easily call the driver in case they need to. This helped us reduce failed deliveries significantly which ultimately saves us a lot of money.`,
    title: 'Head of Ecommerce',
  },
  'sechkov-334': {
    company: 'Ufleet',
    person: 'Atanas Sechkov',
    quote: `It was a great experience working with OBI Poland on the implementation of Ufleet. I have never seen a company of that size move so quickly. They know their business processes inside out and quickly aligned those to our system. We “clicked” from day one and managed to deliver before the deadline. Love the people and the OBI culture.`,
    title: 'Founder',
  },
  'sowinski-169': {
    company: 'OBI',
    person: 'Arkadiusz Sowinski',
    quote: `Now we know not only when our clients are unhappy, but we know the reason why. It is much easier to handle customer cases when you have the full picture in front of you.`,
    title: 'Customer Care Manager',
  },
  'starczynowski-256': {
    company: 'OBI',
    person: 'Tomasz Starczynowski',
    quote: `Today customers are not only comparing the experience with our direct competitors - they are comparing it with the best delivery experience ever. Ufleet has given us the technological edge to improve our home delivery offering and stay ahead in this field.`,
    title: 'Sales Director, Board Member',
  },
  'stoichkov-141': {
    company: 'Metro Cash & Carry',
    person: 'Ivan Stoichkov',
    quote: `Delivery data helps us make smarter decisions. The dashboard allows us to discover inefficiencies and address them when they are still small.`,
    title: 'Delivery Execution Manager',
  },
  'stoichkov-84': {
    company: 'Metro Cash & Carry',
    person: 'Ivan Stoichkov',
    quote: `Route planning is so precise that we no longer worry about missing delivery windows.`,
    title: 'Delivery Execution Manager',
  },
  'yankov-105': {
    company: 'Tmarket',
    person: 'Hristo Yankov',
    quote: `Realtime data on delivery quality and customer satisfaction is instrumental in taking the right decision.`,
    title: 'E-commerce Manager',
  },
  'yankov-118': {
    company: 'Tmarket',
    person: 'Hristo Yankov',
    quote: `The Ufleet team is super responsive. Their help and support during the software implementation process was invaluable.`,
    title: 'E-commerce Manager',
  },
  'yankov-181': {
    company: 'Tmarket',
    person: 'Hristo Yankov',
    quote: `When there's an issue, we can act immediately. The feedback form has been key in helping us address delivery problems straight away and prevent customer frustration from escalating.`,
    title: 'E-commerce Manager',
  },
  'yankov-85': {
    company: 'Tmarket',
    person: 'Hristo Yankov',
    quote: `Acting real time on customer feedback helped us achieve exceptional customer loyalty.`,
    title: 'E-commerce Manager',
  },
  'yotsov-109': {
    company: 'Metro Cash & Carry',
    person: 'Ivan Yotsov',
    quote: `Ufleet is unbelievably effective! It takes just a single click to find the best routes for hundreds of tasks.`,
    title: 'Head of Food Service Delivery',
  },
  'yotsov-119': {
    company: 'Metro Cash & Carry',
    person: 'Ivan Yotsov',
    quote: `Ufleet route optimization helped us save 13% on delivery costs and at the same time improved the quality of our service`,
    title: 'Head of Food Service Delivery',
  },
  'yotsov-129': {
    company: 'Metro Cash & Carry',
    person: 'Ivan Yotsov',
    quote: `Ufleet helped us achieve considerable cost cuts in logistics - fewer miles driven, significantly less idle time, fewer pay hours.`,
    title: 'Head of Food Service Delivery',
  },
} as const;

export type QuoteKey = keyof typeof QUOTES;
